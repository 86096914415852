import React from "react"

import config from '../config';
import imgDefault from '../assets/images/vector-analisis-og.jpg';

interface Props {
  title?: string;
  description?: string;
  flash?: boolean;
  img?: string;
  // content?: string;
  // author?: any;
  // keywords?: string[];
  // twitter?: string;
}

const url = typeof window !== 'undefined' ? window.location.href : '';

export const HeadApi = ({
  title,
  description,
  flash,
  img
}:Props) => {
  return (
    <>
      {/* <html lang={config.lang} /> */}
      <title>{`${title || config.title}`} | Vector Análisis</title>
      <meta name={ 'title'} content={`${title || config.title} | Vector Análisis`} />
      <meta name={ 'description'} content= {`${description || config.description}`} />
      <meta name={'image'} content={ !flash ? `${img || config.featuredImage}` : imgDefault} />
      {/* Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`${title || config.title} | Vector Análisis`} />
      <meta property="og:description" content={`${(description?.raw ? "" : description ) || config.description}`} />
      <meta property="og:image" content={ !flash ? `${img || config.featuredImage}` : imgDefault} />
      <meta property="og:site_name" content={`${title || config.title} | Vector Análisis`} />
      <meta property="og:locale" content={config.lang} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={config.ogType} />
      {/* Google Site */}
      <meta name="google-site-verification" content="1hUyYi8J2xRYn7MEa-O6yVEHHHW1fgDv7TWTOK3vzQE"/>
      <meta name="google-site-verification" content="eKprQ0ID3aGmVs_mulok3GGjwNZ21yxcnWKeOvvKdVs"/>
    </>
  )
}

HeadApi.defaultProps = {
    title: config.title,
    description: config.description,
    img: config.featuredImage,
};

