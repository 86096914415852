/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { PostPreview as PostProps } from '../models/PostPreview';

import { main } from '../theme';

import HeaderLayout from './layout/HeaderLayout';
import HeroLayout from './layout/HeroLayout';
import CustomFooter from './CustomFooter';
import ModalSuscribe from '../components/layout/ModalSuscribre';


const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingRight: '20px',
      paddingLeft: '20px',
      marginRight: 'auto',
      marginLeft: 'auto',
      width: '100%',
      '@media (min-width: 576px)': {
        maxWidth: '540px',
      },
      '@media (min-width: 768px)': {
        maxWidth: '720px',
      },
      '@media (min-width: 992px)': {
        maxWidth: '960px',
      },
      '@media (min-width: 1200px)': {
        maxWidth: '1440px',
      },
    },
  })
);

interface Props {
  children?: React.ReactNode;
  post: PostProps;
  showFooter?: boolean;
}

const PostLayout = ({
  children,
  post,
  showFooter = true,
}: Props): JSX.Element => {
  const classes = useStyles();

  // const postType =
  const content = post?.content;
  const author = post?.authorObject;
  const twitter = post?.authorObject?.twitter;
  const keywords = post?.tags?.map((tag: any) => {
    return tag.nombre;
  });

  // console.log("[POST layout]",post);

  //functions modal suscribe
  const [open, setOpen] = useState(false);
  function openSuscribe(){
    setOpen(true);
  }
  function closeSuscribe(){
    setOpen(false);
  }
  return (
    <>

      <ThemeProvider theme={main}>
        <HeaderLayout ticker={false} openModal={openSuscribe}/>
        <HeroLayout post={post}/>
        <div className={classes.container}>
          <div style={{ marginTop: '2em' }}>{children}</div>
        </div>
        { showFooter && <CustomFooter openModal={openSuscribe}/> }
        { open && <ModalSuscribe closeModal={closeSuscribe}/> }
      </ThemeProvider>
    </>
  );
};

export default PostLayout;