/**
 * Common App Configurations.
 */

module.exports = {
  title: 'Vector Análisis',
  description:
    'Análisis y pronóstico de los indicadores líderes y eventos más relevantes de los mercados financieros',
  siteUrl: 'https://vectoranalisis.mx/',
  siteUrlNoSlash: 'https://vectoranalisis.mx',
  featuredImage: 'https://vectoranalisis.mx/static/images/vector/analisis.png',
  color: '#FF6600',
  icon: 'src/assets/favicon.png', // This path is relative to the root of the site.
  lang:"es_MX",
  display: 'browser',
  ogType: 'article',
  ogImgType: 'image/png',
  ogImgWidth: '224',
  ogImgHeight: '256',
  twitter: '@vectoranalisis',
  twitterCard: 'summary_large_image',
  twitterImgAlt: 'Logo Vector Casa de Bolsa',
  facebook: 'https://www.facebook.com/vectorcasadebolsa/',
};
