const getCategoryColor = (content: string): string => {
  if (content === 'rentaFija') {
    return '#959390';
  }
  if (content === 'rentaVariable') {
    return '#bee1e2';
  }
  if (content === 'tecnico') {
    return '#d1dd00';
  }
  if (content === 'rentaFija') {
    return '#959390';
  }
  if (content === 'internacional') {
    return '#323232';
  }
  if (content === 'economico') {
    return '#002E63';
  }
  if (content === 'general') {
    return '#000C1A';
  }
  return '#FFFFFF';
};

export default getCategoryColor;
