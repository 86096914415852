import React, { useState, useEffect } from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';
import { Button } from '@material-ui/core';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

function getWindowDimensions() {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const w = 768;
  const h = 768;
  return {
    w,
    h,
  };
}

// eslint-disable-next-line import/export
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize(): void {
      setWindowDimensions(getWindowDimensions());
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return windowDimensions;
}

const PDFPreview = (pdf: string): JSX.Element => {
  const { height, width } = useWindowDimensions();
  let scl: unknown = 0.5;
  if (width > 768) {
    scl = 1;
  }

  const [numPaginas, setNumPaginas] = useState(4);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    if (numPages <= 4) {
      setNumPaginas(numPages);
    }
    setPageNumber(1);
  };
  function changePage(offset: number): void {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage(): void {
    changePage(-1);
  }

  function nextPage(): void {
    changePage(1);
  }

  // const pdfFile = `https:${pdf.pdf}`;
  const pdfFile = `https:${pdf.pdf}`;
  // console.log(pdfFile)

  if (!pdfFile.endsWith('.pdf')) {
    return <div></div>;
  }
  return (
    <div style={{ marginBottom: 20 , width:"100%"}}>
      <div className="pdf-container" style={{ width: 'auto' }}>
        <iframe src={pdfFile} frameBorder="0" style={{position:"relative", width:"100%", height:"60vh"}}></iframe>
        {/* <Document
          file={pdfFile}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
        >
          <Page
            pageNumber={pageNumber}
            // width={300}
            scale={scl}
            renderMode="svg"
          />
        </Document> */}
        <div>
          {/* <p>
            Página {pageNumber || (numPaginas ? 1 : '--')} de{' '}
            {numPaginas || '--'}
          </p>
          <Button
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            Previo
          </Button>
          <Button
            type="button"
            disabled={pageNumber >= numPaginas}
            onClick={nextPage}
          >
            Siguiente
          </Button> */}
          <Button color="secondary" href={pdfFile} target="_blank">
            Abrir o Descargar
          </Button>
        </div>
      </div>
    </div>
  );
};
export default PDFPreview;
