/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Header from './Header';
import CustomFooter from './CustomFooter';
import { main } from '../theme';
import Hero from './Hero';
import { Flash as FlashProps } from '../models/Flash';
// import IconTitle from '../components/IconTitle';
import config from '../config';
import HeaderLayout from './layout/HeaderLayout';
import HeroLayout from './layout/HeroLayout';

import ModalSuscribe from '../components/layout/ModalSuscribre';


const useStyles = makeStyles(() =>
  createStyles({
    title: {
      marginTop: '110px',
      marginBottom: '15px',
      fontSize: '45px',
      fontWeight: 900,
    },
    container: {
      paddingRight: '20px',
      paddingLeft: '20px',
      marginRight: 'auto',
      marginLeft: 'auto',
      width: '100%',
      '@media (min-width: 576px)': {
        maxWidth: '540px',
      },
      '@media (min-width: 768px)': {
        maxWidth: '720px',
      },
      '@media (min-width: 992px)': {
        maxWidth: '960px',
      },
      '@media (min-width: 1200px)': {
        maxWidth: '1140px',
      },
    },
  })
);

interface Props {
  children?: React.ReactNode;
  post: FlashProps;
  showFooter?: boolean;
  color?: string;
}

const FlashLayout = ({
  children,
  post,
  color,
  showFooter = true,
}: Props): JSX.Element => {
  const classes = useStyles();

  const logoBg =
    post?.contentSlug !== 'cartera' ? 'transparent' : 'not-transparent';

  // const postType =
  const content = post?.content;
  const author = post?.authorObject;
  const twitter = post?.authorObject?.twitter;
  const keywords = post?.tags?.map((tag: any) => {
    return tag.nombre;
  });

  // console.log(`https:${author?.avatar?.src}`);
  //functions modal suscribe
  const [open, setOpen] = useState(false);
  function openSuscribe(){
    setOpen(true);
  }
  function closeSuscribe(){
    setOpen(false);
  }
  return (
    <>

      <ThemeProvider theme={main}>
        {/* <CssBaseline /> */}
        <HeaderLayout ticker={false} openModal={openSuscribe}/>
        <HeroLayout post={post}/>

        {/* <Header color={logoBg} />
        <Hero post={post} color={color} /> */}
        <div className={classes.container}>
          <div style={{ marginTop: '2em' }}>{children}</div>
        </div>
        {showFooter && <CustomFooter openModal={openSuscribe}/>}
        {
          open && <ModalSuscribe closeModal={closeSuscribe}/>
        }
      </ThemeProvider>
    </>
  );
};

export default FlashLayout;
